<template>
    <div class="uk-width-1-1">
        <!-- Filter -->
        <div class="uk-width-1-1 uk-grid-small uk-child-width-1-3 uk-margin-top" uk-grid>
            <div class="uk-margin-remove">
                <label class="uk-form-label" for="fullname">Fullname</label>
                <div class="uk-form-controls uk-width-1-1">
                    <multiselect
                        id="fullname"
                        v-model="selectedFilter.fullname"
                        placeholder="Type a Name"
                        label="user_fullname"
                        track-by="_id"
                        :options="userOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :searchable="true"
                        :loading='userOptionsLoading'
                        :internal-search="false"
                        :options-limit="100"
                        :disabled="disabledFilterFullname"
                        @search-change="searchUserOptionsByFullname"
                        @input="doSearchLiveStreamChat"
                    />
                </div>
            </div>
            <div class="uk-margin-remove">
                <label class="uk-form-label" for="email">Email</label>
                <div class="uk-width-1-1">
                    <multiselect
                        id="email"
                        v-model="selectedFilter.email"
                        placeholder="Filter by Email"
                        label="user_email"
                        track-by="_id"
                        :options="userOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :searchable="true"
                        :loading='userOptionsLoading'
                        :internal-search="false"
                        :options-limit="100"
                        :disabled="disabledFilterEmail"
                        @search-change="searchUserOptionsByEmail"
                        @input="doSearchLiveStreamChat"
                    />
                </div>
            </div>
            <div class="uk-margin-remove">
                <label class="uk-form-label" for="sortBy">Sort by</label>
                <div class="uk-form-controls uk-width-1-1">
                    <select id="sortBy" class="uk-select" aria-label="Select" v-model="meta.sort_by" @change.prevent="doSearchLiveStreamChat">
                        <option value="oldest">Oldest</option>
                        <option value="latest">Latest</option>
                    </select>
                </div>
            </div>
        </div>
        <!-- End filter -->

        <!-- Chat list table -->
        <div class="uk-margin-top uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-striped uk-table-middle">
                    <thead>
                        <tr>
                            <th class="uk-text-nowrap uk-table-shrink uk-text-center">No.</th>
                            <th class="uk-text-left uk-text-nowrap">User</th>
                            <th class="uk-text-left uk-text-nowrap">
                                <p class="uk-margin-remove">Roles</p>
                                <p class="uk-margin-remove">Type</p> 
                            </th>
                            <th class="uk-text-left uk-text-nowrap">Email</th>
                            <th class="uk-text-left uk-text-nowrap">
                                <p class="uk-margin-remove">Telephone</p>
                                <p class="uk-margin-remove">Number</p>
                            </th>
                            <th class="uk-text-left uk-text-nowrap">Messages</th>
                            <th class="uk-text-left uk-text-nowrap">
                                <p class="uk-margin-remove">Time stamp</p>
                                <p class="uk-margin-remove">(WIB)</p>
                            </th>
                        </tr>
                    </thead>
                    <loading-table v-if="tableLoading" :colspan="7" />
                    <template v-else>
                        <tbody v-if="liveStreamChat.totalDocs > 0">
                            <tr v-for="(data, index) in liveStreamChat.docs" :key="index">
                                <td class="uk-text-nowrap uk-table-shrink uk-text-center">{{ autoIncrementNumber({ pagingCounter: liveStreamChat.pagingCounter, index }) }}</td>
                                <td class="uk-flex uk-flex-middle uk-flex-left uk-text-nowrap uk-text-left uk-text-nowrap">
                                    <img 
                                        v-if="data && data.user_photos && data.user_photos.file_url" 
                                        :src="data.user_photos.file_url" 
                                        :alt="`${data.user_fullname} photos`" 
                                        class="uk-preserve-width uk-border-circle user-photos" 
                                    />
                                    <img
                                        v-else 
                                        :src="`${images}/person.svg`"
                                        alt="Person photos" 
                                        class="uk-preserve-width uk-border-circle user-photos" 
                                    />
                                    <p class="uk-margin-remove-vertical uk-margin-small-left">{{ data && data.user_fullname || '-' }}</p>
                                </td>
                                <td class="uk-text-left uk-text-nowrap">{{ data && data.user_role_type || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap" :uk-tooltip="data && data.user_email">{{ data && preview({ data: data.user_email, limit: 20 }) || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ data && data.user_phone_number || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap" :uk-tooltip="data && data.messages">{{ data && preview({ data: data.messages, limit: 100 }) || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ data && formatterHourMinuteFromDate({ date: data.created_at }) || '-' }}</td>
                            </tr>
                        </tbody>
                        <empty-table v-else :colspan="7" />
                    </template>
                </table>
            </div>
            <pagination
                :total-data="liveStreamChat.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <!-- End chat list table -->
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions } from 'vuex';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import formatter from '@/utils/formatter';

export default {
    name: 'ChatTab',
    components: {
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            internetConnection: true,
            tableLoading: false,
            userOptionsLoading: false,
            userOptions: [],
            disabledFilterFullname: false,
            disabledFilterEmail: false,
            selectedFilter: {
                fullname: [],
                email: []
            },
            liveStreamChat: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0
            },
            meta: {
                limit: 10,
                page: 1,
                live_stream_id: '',
                fullname: null,
                email: null,
                sort_by: 'oldest'
            }
        };
    },
    watch: {
        async meta() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                await this.setLiveStreamChatData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        'meta.fullname'(val) {
            if (val) {
                this.disabledFilterEmail = true;
            } else {
                this.disabledFilterEmail = false;
            }
        },
        'meta.email'(val) {
            if (val) {
                this.disabledFilterFullname = true;
            } else {
                this.disabledFilterFullname = false;
            }
        },
    },
    async mounted() {
        try {
            if (!navigator.onLine) return this.internetConnection = false;
            await this.setLiveStreamChatData();
        } catch (error) {
            notificationDanger(error);
        }
    },
    methods: {
        ...mapActions({
            getLiveStreamChatListPaginate: 'liveStreamChatV4/getLiveStreamChatListPaginate',
            getUserOptions: 'liveStreamChatV4/getUserOptions',
        }),
        async setLiveStreamChatData() {
            try {
                this.tableLoading = true;
                this.meta.live_stream_id = this.$route.params.id;
                const response = await this.getLiveStreamChatListPaginate(this.meta);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const docs = response.data.result && response.data.result.docs || [];
                    const totalDocs = response.data.result && response.data.result.totalDocs || 0;
                    const pagingCounter = response.data.result && response.data.result.pagingCounter || 0; 

                    this.liveStreamChat.docs = docs;
                    this.liveStreamChat.totalDocs = totalDocs;
                    this.liveStreamChat.pagingCounter = pagingCounter;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async doSearchLiveStreamChat() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;

                if (this.selectedFilter.fullname.length > 0) {
                    const distinctUserFullname = [...new Set(this.selectedFilter.fullname.map((item) => String(item.user_fullname)))];
                    this.meta.fullname = distinctUserFullname.join();
                } else {
                    this.meta.fullname = null;
                }

                if (this.selectedFilter.email.length > 0) {
                    const distinctUserEmail = [...new Set(this.selectedFilter.email.map((item) => String(item.user_email)))];
                    this.meta.email = distinctUserEmail.join();
                } else {
                    this.meta.email = null;
                }

                this.tableLoading = true;
                this.meta.limit = 10;
                this.meta.page = 1;
                await this.setLiveStreamChatData();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async searchUserOptionsByFullname(query) {
            try {
                const payload = { fullname: query };
                await this.setUserOptions(payload);                
            } catch (error) {
                notificationDanger(error);
            }
        },
        async searchUserOptionsByEmail(query) {
            try {
                const payload = { email: query };
                await this.setUserOptions(payload);  
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setUserOptions(query) {
            try {
                this.userOptionsLoading = true;
                const response = await this.getUserOptions(query);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result;
                    this.userOptions = result;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.userOptionsLoading = false;
            }
        },
        autoIncrementNumber({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },
        formatterHourMinuteFromDate({ date }) {
            return formatter.hourMinuteFromDate(date);
        }, 
        preview({ data, limit }) {
            if (typeof data !== 'string') throw new Error('Data is not string');
            
            if (data.length < limit) {
                return data;
            } else {
                return data.substring(0, limit)+'...';
            }
        }, 
        changeLimit(e) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    } 
};
</script>

<style scoped>
.user-photos {
    width: 100%;
    height: 100%;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    object-fit: cover;
    object-position: center;
}
</style>
